import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import { Col, Row } from "react-bootstrap";
import { Tabs, useSessionStore } from "../../Stores/SessionStore";
import { faEllipsisStroke, faHouse, faUserPen } from "@fortawesome/pro-thin-svg-icons";
import { useNavigate } from "react-router-dom";

function TabButton({ icon, text, onClick, active }) {
	return 	<span className={ `d-flex flex-column p-1 text-center align-items-center ${active ? "text-primary" : "text-black-50"}` } onClick={ onClick }>
				<FontAwesomeIcon transform={ { size: 20 } } fixedWidth icon={ icon }></FontAwesomeIcon>
				<span style={{ fontSize: "10px" }}>{text}</span>
			</span>
}

const BottomTab = observer(function BottomTab() {
	const store = useSessionStore();
	const navigate = useNavigate();

	function handleButtonClicked(tab, url) {
		store.setCurrentTab(tab);
		navigate(url);
	}

	return 	<Row className="bg-white border-top p-1 py-2 pb-1 user-select-none">
				<Col className="d-flex justify-content-around">
					<TabButton 
						icon={ faHouse } 
						active={ store.CurrentTab === Tabs.Home } 
						text="Home" 
						onClick={ () => handleButtonClicked(Tabs.Home, "/") } 
					/>			
					<TabButton 
						icon={ faUserPen } 
						active={ store.CurrentTab === Tabs.UpdateDetails } 
						text="Update Details" 
						onClick={ () => handleButtonClicked(Tabs.UpdateDetails, "/updatedetails") } 
					/>
					<TabButton 
						icon={ faEllipsisStroke } 
						active={ store.CurrentTab === Tabs.More } 
						text="More" 
						onClick={ () => handleButtonClicked(Tabs.More, "/more") } 
					/>
				</Col>
			</Row>
});

export default BottomTab;