import { Button, Modal } from "react-bootstrap";

export default function StatementDownloadedModal({ show, onClose, multiple }) {
	return 	<Modal show={ show } centered>
				<Modal.Body className="py-5 d-flex flex-column align-items-center">
					<div className="text-primary text-center pb-5 px-2">
						Your { multiple ? " statements have " : " statement has " } been downloaded.
					</div>
					<Button className="w-100" style={{ maxWidth: "15em" }} onClick={ () => onClose() }>Okay</Button>
				</Modal.Body>
			</Modal>
}