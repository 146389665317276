import { Stack } from "react-bootstrap";
import Page from "../Core/Page";
import MemberBalances from "./MemberBalances";
import WithdrawalInfo from "./WithdrawalInfo";
import WithdrawalSteps, { Steps } from "./WithdrawalSteps/WithdrawalSteps";
import WithdrawalStats from "./WithdrawalStats";
import { useEffect, useState } from "react";
import MessageModal from "../Core/MessageModal";
import { useSessionStore } from "../../Stores/SessionStore";
import { useNavigate } from "react-router-dom";

export default function Withdrawal() {
	const store = useSessionStore();
	const navigate = useNavigate();

	const [currentStep, setCurrentStep] = useState(Steps.AmountAndTime);
	const [showPendingWithdrawalModal, setShowPendingWithdrawalModal] = useState(false);
	const [showExistingWithdrawalModal, setShowExistingWithdrawalModal] = useState(false);

	const pendingWithdrawals = store.MemberUserStore?.MemberUserState?.TotalPendingWithdrawals;
	const canDoMoreWithdrawalsThisMonth = store.MemberUserStore?.CanDoMoreWithdrawalsThisMonth();

	function closePendingWithdrawalModal() {
		setShowPendingWithdrawalModal(false);
		navigate("/");
	}

	function closeExistingWithdrawalModal(result) {
		setShowExistingWithdrawalModal(false);

		if(!result) {
			navigate("/");
		}
	}

	useEffect(() => {
		if(store) {
			if(pendingWithdrawals) {
				setShowPendingWithdrawalModal(true);
				return;
			}

			if(!canDoMoreWithdrawalsThisMonth) {
				setShowExistingWithdrawalModal(true);
				return;
			}
		}
	}, []);

	return 	<Page bgBlue>
				{
					!pendingWithdrawals &&
					<div className="h-100 d-flex" style={{ paddingBottom: "10rem" }}>
						<Stack gap={3} className="justify-content-center">
							{
								currentStep === Steps.AmountAndTime &&
								<MemberBalances />
							}

							<WithdrawalSteps onStepChange={ (step) => setCurrentStep(step) }/>
							
							{
								currentStep === Steps.AmountAndTime &&
								<WithdrawalStats />
							}
						</Stack>
						<WithdrawalInfo />
					</div>
				}
				
				<MessageModal 
					message="An existing withdrawal is already pending. No further withdrawals are currently permitted."
					show={ showPendingWithdrawalModal }
					onClose={ closePendingWithdrawalModal }
				/>
				<MessageModal 
					message={ <span>No further withdrawals are permitted for this month. See the <a href="#/faqs">FAQs here</a> for more details.</span>} 
					show={ showExistingWithdrawalModal }
					onClose={ closeExistingWithdrawalModal }
					showCancel={ true }
				/>
			</Page>
}