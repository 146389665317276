import { Stack, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Page from "../Core/Page";
import Logo from "../Core/Logo";

export default function Landing() {
    const navigate = useNavigate();

    return (
        <Page bgBlue>
            <Logo />
            <Stack className="vstack h-50">
                <Button className="d-block bg-white text-primary w-100 mt-4" onClick={ () => navigate("/login") }>Log in</Button>
                <Button className="d-block bg-white text-primary w-100 mt-4" onClick={ () => navigate("/register") }>Register</Button>
            </Stack>
        </Page>
    )
}