import { useState } from "react";
import { Stack, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSessionStore } from "../../../Stores/SessionStore";
import Page from "../../Core/Page";
import Panel from "../../Core/Panel";
import Logo from "../../Core/Logo";
import ErrorModal from "../ErrorModal";
import PasswordField from "../PasswordField";
import IDNumberField from "../IDNumberField";
import PasswordChecklist from "../Register/PasswordChecklist";
import RegisteredModal from "../Register/RegisteredModal";

export default function Register() {
	const store = useSessionStore();
	const navigate = useNavigate();

	const [IdNumber, setIdNumber] = useState("");
	const [password, setPassword] = useState("");
	const [serviceNumber, setServiceNumber] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [showRegistered, setShowRegistered] = useState(false);
	const [showRegisterError, setShowRegisterError] = useState(false);
	const [registerErrorMessage, setRegisterErrorMessage] = useState("There was an error registering. Please try again.");
	const [registering, setRegistering] = useState(false);
	const [consentChecked, setConsentChecked] = useState(false);

	async function register() {
		setRegistering(true);
		
		let result = await store.MemberUsersService.Register(IdNumber, serviceNumber, phoneNumber, password, confirmPassword);
		
		setRegistering(false);

		if (result && result.Success) {
			
			setShowRegistered(true);
		} else {
			setShowRegisterError(true);
			setRegisterErrorMessage(result.Message);
			return false;
		}
	}

	return (
		<Page bgBlue>
			<Logo />
			<Stack className="justify-content-start mb-2 mt-5">
				<Panel>
					<Form>
						<Stack gap={3}>
							<IDNumberField value={IdNumber} onChange={setIdNumber}/>
							<Form.Group>
								<Form.Label>Service Number</Form.Label>
								{
									serviceNumber.length < 5 &&
									<Form.Text className="text-danger ms-1">(Service number must contain 5 or more numbers)</Form.Text>
								}								
								<Form.Control
									value={serviceNumber}
									className="bg-white"
									onChange={(e) => setServiceNumber(isNaN(e.target.value) ? serviceNumber : e.target.value)}
									maxLength={7}
								>
								</Form.Control>
							</Form.Group>
							<Form.Group>
								<Form.Label>Mobile Number</Form.Label>
								{
									phoneNumber.length < 10 &&
									<Form.Text className="text-danger ms-1">(Mobile number must be 10 digits)</Form.Text>
								}								
								<Form.Control
									value={phoneNumber}
									className="bg-white"
									onChange={(e) => setPhoneNumber(isNaN(e.target.value) ? phoneNumber : e.target.value)}
									maxLength={10}
								>
								</Form.Control>
							</Form.Group>
							<PasswordField                                    
								label="Password"
								value={password}
								showPasswordError={false}
								onChange={setPassword}
							/>
							<PasswordField                                    
								label="Repeat Password"
								value={confirmPassword}
								passwordError={password !== confirmPassword ? "(Password doesn't match)" : null}
								onChange={setConfirmPassword}
							/>
							<PasswordChecklist password={password}/>
							<Form.Check 
								disabled={ false}
								value={ consentChecked } 
								onChange={ e => {setConsentChecked(!consentChecked)} } 
								type="checkbox" 
								label={ <>By selecting "Register", you are confirming that you have read and agree to Durban Municiple Thrift Fund's <a href="Terms and conditions.pdf">Terms and Conditions</a> and <a href="Privacy notice.pdf">Privacy notice.</a></>}
							/>
							{
								!consentChecked &&
								<Form.Text className="text-danger ms-1">(You must agree to the Terms and conditions and Privacy notice)</Form.Text>
							}
							<Button
								className="mt-3"
								onClick={() => register()}
								disabled={ 
									registering || 
									password.length === 0 || password !== confirmPassword || 
									!IdNumber || IdNumber.length !== 13 ||
									!serviceNumber || serviceNumber.length < 5 ||
									!phoneNumber || phoneNumber.length < 10 || !consentChecked
								}
							>
								{registering ? "Registering..." : "Register"}
							</Button>
							<Button onClick={ () => navigate("/") }>Cancel</Button>
						</Stack>
					</Form>
				</Panel>
			</Stack>
			<RegisteredModal show={showRegistered} onClose={() => {setShowRegistered(false); navigate("/login")}} />
			<ErrorModal show={showRegisterError} onClose={() => setShowRegisterError(false)} message={registerErrorMessage} />
		</Page>
	)
}