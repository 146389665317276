import { Button, Modal, Stack } from "react-bootstrap";

export default function ErrorModal({ show, onClose, message }) {
	
    return 	<Modal show={ show } centered>
				<Modal.Body className="py-5">
					<div className="text-danger text-center pb-5 px-2">
						{message}
					</div>
					<Stack gap={3}>
						<Button onClick={ () => onClose(true) }>Okay</Button>
					</Stack>
				</Modal.Body>
			</Modal>
}