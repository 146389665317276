import { observer } from "mobx-react-lite";
import { Container } from "react-bootstrap";
import { HashRouter } from "react-router-dom";
import { useSessionStore } from "../Stores/SessionStore";
import Main from "./Main";
import Auth from "./Auth/Auth";

const App = observer(function App() {
	const store = useSessionStore();

	return 	<HashRouter>
				<Container fluid className={`h-100 d-flex flex-column`}>
					{
						store.UserIsLoggedIn &&
						<Main></Main>
					}
					{
						!store.UserIsLoggedIn &&
						<Auth></Auth>
					}
				</Container>
			</HashRouter>
});

export default App;