import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useSessionStore } from "../../Stores/SessionStore";
import Page from "../Core/Page";
import { Stack, Col } from "react-bootstrap";
import {APIProvider, Map, AdvancedMarker} from '@vis.gl/react-google-maps';

export default function ContactUs() {
    const store = useSessionStore();
    const addressHref = "https://www.google.com/maps/dir/?api=1&destination=16+Magwaza+Maphalala+St,+Greyville,+Berea,+4001";
    const latLong = {lat: -29.86152561374524, lng: 31.008089725496347};

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const LinkButton = ({href, title, children}) => {
        return <Link
                    className="text-decoration-none p-3 rounded-3 shadow border-0"
                    to='#'
                    onClick={(e) => {
                        window.location.href = href;
                        e.preventDefault();
                    }}
                >
                    <div className="w-100">{title}</div>
                    <div className="w-100">{children}</div>
                </Link>
    }

	return 	<Page title="Contact us">
                <Col className={`col-${width < height ? 12 : 6}`}>
                    <Stack gap={3} className="px-5 pt-5">
                        <LinkButton href="tel:+27313011794" title="Call">031 301 1794</LinkButton>
                        <LinkButton href="mailto:dbnthriftass@mweb.co.za" title="Email">dbnthriftassociation.co.za</LinkButton>
                        <LinkButton href={addressHref} title="Address">
                            <div>16 Magwaza Maphalala St</div>
                            <div>Greyville</div>
                            <div>Durban, 4001</div>
                        </LinkButton>
                    </Stack>
                </Col>
                <Col className={`col-${width < height ? 12 : 6} rounded p-5 h-${width < height ? 50 : 100}`}>
                    <APIProvider apiKey={store.Config.googleMapsAPIKey}>
                        <Map
                            className="rounded-3 border-0 shadow h-100"
                            defaultCenter={latLong}
                            defaultZoom={16}
                            gestureHandling={'greedy'}
                            disableDefaultUI={true}
                            mapId={"c7740ff9-8275-4c3d-a08c-b2bd0649d1e5"}                            
                        >
                            <AdvancedMarker
                                position={latLong}
                                gmpClickable={true}
                                onClick={(e) => {window.location.href = addressHref;}}
                            />
                        </Map>
                    </APIProvider>
                </Col>
            </Page>
}