import { makeAutoObservable, runInAction } from "mobx";

/**
 * @typedef NotificationStore
 * @type {NotificationStore}
*/
export class NotificationStore {
	GettingNotifications = false;
	Notifications = null;

	constructor(notificationService) {
		this.NotificationService = notificationService;

		makeAutoObservable(this);
	}

	async LoadNotifications() {
		this.GettingNotifications = true;

		let result = await this.NotificationService.GetMemberUserNotifications();

		if(result && result.Success) {
			runInAction(() => {
				this.Notifications = result.Data;
				this.GettingNotifications = false;
			});
		}
	}
}