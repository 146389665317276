import { Button, Modal, Stack } from "react-bootstrap";

export default function RegisteredModal({ show, onClose }) {
	
    return 	<Modal show={ show } centered>
				<Modal.Body className="py-5">
					<div className="text-center pb-5 px-2">
						You have been successfully Registered.
                        You will be redirected to the login screen.
					</div>
					<Stack gap={3}>
						<Button onClick={ () => onClose(true) }>Okay</Button>
					</Stack>
				</Modal.Body>
			</Modal>
}