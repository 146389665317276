import { useEffect, useState } from "react";
import { Tabs, useSessionStore } from "../../Stores/SessionStore";
import TabPage from "../Core/TabPage";
import { Col, Row, Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket, faBell, faCommentQuestion, faFileLines, faLock, faPhone } from "@fortawesome/pro-thin-svg-icons";
import { useNavigate } from "react-router-dom";
import ConfirmLogoutModal from "../Home/ConfirmLogoutModal";

function Feature({ label, icon, url, onClick }) {
	const navigate = useNavigate();

	function handleClick() {
		if(url) {
			navigate(url);
		} else if(onClick) {
			onClick();
		}
	}

	return 	<Row className="pb-2 border-bottom text-primary user-select-none" onClick={ handleClick }>
				<div className="d-flex align-items-center ps-0">
					<FontAwesomeIcon fixedWidth icon={icon} />
					<span className="ps-2">{label}</span>
				</div>
			</Row>
}

export default function More() {
	const store = useSessionStore();
	const navigate = useNavigate();

	const [confirmingLogout, setConfirmingLogout] = useState(false);

	function handleLogoutConfirmed(logout) {
		setConfirmingLogout(false);
		
		if(logout) {
			store.Logout();
		}
		navigate("/");
	}
	
	useEffect(() => {
		store.setCurrentTab(Tabs.More);
	}, []);

	return 	<TabPage bgWhite>
				<Row className="py-1 fs-5 border-bottom border-primary bg-white text-primary">
						<Col>
							<span className="ms-1">More Features</span>
						</Col>
					</Row>
				<Row>
					<Col>
						<Stack gap={2} className="px-3 pt-2">
							<Feature label="FAQ's" icon={ faCommentQuestion } onClick={ () => navigate("/faqs") } />
							<Feature label="Contact us" icon={ faPhone } onClick={ () => navigate("/contactus") }/>
							<Feature label="Privacy notice" icon={ faLock } onClick={ () => navigate("/privacynotice") }/>
							<Feature label="Terms and conditions" icon={ faFileLines } onClick={ () => navigate("/termsandconditions") }/>
							<Feature label="Notifications" icon={ faBell } onClick={ () => navigate("/notifications") } />
							<Feature label="Logout" icon={ faArrowRightFromBracket } onClick={ () => setConfirmingLogout(true) }/>
						</Stack>
					</Col>
				</Row>
				<ConfirmLogoutModal show={ confirmingLogout } onClose={ handleLogoutConfirmed } />
			</TabPage>
}