import { Button, Col, Row, Stack } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";

export default function Complete({ onOkClicked }) {
	const store = useSessionStore();

	async function handleOkClicked() {
		store.MemberUserStore.LoadMemberUserState(true);
		onOkClicked();
	}

	return 	<Row className="my-5">
				<Col>
					<Stack>
						<h5 className="text-center text-primary mb-5">Your withdrawal is being processed.</h5>
						<Button onClick={ handleOkClicked }>Okay</Button>
					</Stack>
				</Col>
			</Row>
}