import { DateTime } from "luxon";
import { makeAutoObservable, runInAction } from "mobx";

/**
 * @typedef MemberUserBankAccount
 * @type {object}
 * @property {string} AccountNumber
 * @property {string} Payee
 * @property {string} BankName
 */

/**
 * @typedef MemberBalanceSummary
 * @type {object}
 * @property {number} MaxTransfer
 * @property {number} FreeBalance
 * @property {number} CurrentBalance
 */

/**
 * @typedef Address
 * @type {object}
 * @property {string} Line1
 * @property {string} Line2
 * @property {string} Line3
 * @property {string} Line4
 */

/**
 * @typedef MemberUserDetails
 * @type {object}
 * @property {number} ServiceNumber
 * @property {string} IdNumber
 * @property {string} MobileNumber
 * @property {string} EmailAddress
 * @property {Address} Address
 */

/**
 * @typedef PendingMemberUserWithdrawal
 * @type {object}
 * @property {number} WithdrawalAmount
 * @property {string} WithdrawalDate
 * @property {string} TransactionId
 */

/**
 * @typedef MemberUserState
 * @type {object}
 * @property {MemberBalanceSummary} BalanceSummary
 * @property { PendingMemberUserWithdrawal[] } PendingMemberUserWithdrawals
 * @property {number} TotalPendingWithdrawals
 * @property {number} TotalWithdrawalsThisMonth
 * @property {number} TotalWithdrawalsThisYear
 * @property {MemberUserBankAccount} BankDetails
 * @property {string} MonthEndDate
 * @property {string} YearEndDate
 * @property {MemberUserDetails} MemberDetails
 * @property {string} OfficeClosedFrom
 * @property {string} OfficeClosedTo
 */

/**
 * @typedef MemberUserStore
 * @type {MemberUserStore}
*/
export class MemberUserStore {
	/**@type {MemberUserState} */ MemberUserState = null;

	/**@type {import("../Services/MemberUsersService").MemberUsersService} */ MemberUsersService = null;
	RefreshingState = false;

	constructor(memberUsersService) {
		this.MemberUsersService = memberUsersService;

		makeAutoObservable(this);
	}

	async LoadMemberUserState(refresh) {
		if(!refresh) {
			this.MemberUserState = null;
		}

		this.RefreshingState = true;

		let result = await this.MemberUsersService.GetMemberState();

		if(result && result.Success) {
			runInAction(() => {
				this.MemberUserState = result.Data;
				this.RefreshingState = false;
			});
		}

		return result.Success
	}

	CanDoMoreWithdrawalsThisMonth() {
		return !this.MemberUserState?.TotalWithdrawalsThisMonth || (this.MemberUserState?.TotalWithdrawalsThisMonth < 2 && DateTime.local().month === 1);
	}
}