import { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/pro-thin-svg-icons";

export default function PasswordField({label, value, showPasswordError, passwordError, onChange }) {
    const [showPassword, setShowPassword] = useState(false);
    
    return (
		<Form.Group>
			<Form.Label>{label}</Form.Label>
			{
				passwordError &&
				<Form.Text className="text-danger ms-1">{passwordError}</Form.Text>
			}
			<InputGroup>
				<Form.Control
					value={value}
					type={showPassword ? "type" : "password"}
					className="bg-white"
					onChange={(e) => onChange(e.target.value)}
				>                                                
				</Form.Control>
				<InputGroup.Text>
					<FontAwesomeIcon
						className="text-secondary"
						icon={ showPassword ? faEyeSlash : faEye}
						fixedWidth
						onClick={() => {setShowPassword(!showPassword)}}
					/>
				</InputGroup.Text>
			</InputGroup>
		</Form.Group>
    )
}