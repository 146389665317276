import { Col, Row } from "react-bootstrap";
import BottomTab from "./BottomTab";
import { useEffect } from "react";

export default function TabPage({ rightIcon, leftIcon, children, bgWhite }) {
	useEffect(() => {
		if(bgWhite) {
			document.body.classList.add("bg-white");
			document.body.classList.remove("bg-primary");
		} else {
			document.body.classList.remove("bg-white");
			document.body.classList.add("bg-primary");
		}
	}, []);

	return 	<div style={{ paddingBottom: "4.4em" }} className="flex-grow-1">
				{
					(leftIcon || rightIcon) &&
					<Row className="py-2 text-white fs-3">
						{
							leftIcon &&
							<Col>{ leftIcon }</Col>
						}
						{
							rightIcon &&
							<Col className="d-flex justify-content-end">{ rightIcon }</Col>
						}
					</Row>
				}
				<Row className={ `flex-grow-1${bgWhite ? " bg-white" : ""}` }>
					<Col>
						{ children }
					</Col>
				</Row>
				<Row className="fixed-bottom">
					<Col>
						<BottomTab />
					</Col>
				</Row>
			</div>
}