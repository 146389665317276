import { DateTime } from "luxon";
import { useState } from "react";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import { NumericFormat } from "react-number-format";

export default function AmountAndTime({ onContinueClicked, onCancelClicked, withdrawalData }) {
	const store = useSessionStore();
	const state = store.MemberUserStore.MemberUserState;
	const withdrawalsThisMonth = !store.MemberUserStore?.CanDoMoreWithdrawalsThisMonth();
	
	const [amount, setAmount] = useState(withdrawalData?.Amount ?? null);
	const [date, setDate] = useState(withdrawalData?.Date ?? getMinDate());
	const [showAmountError, setShowAmountError] = useState(false);

	function handleAmountUpdated(newAmount) {
		if(newAmount > state.BalanceSummary.MaxTransfer) {
			setAmount(state.BalanceSummary.MaxTransfer);
		} else if (newAmount < 0) {
			setAmount(0);
		} else {
			setAmount(newAmount);
		}
	}

	function handleDateChanged(newDate) {
		if(!withdrawalsThisMonth) {
			if(newDate) {
				setDate(newDate);
			} else {
				setDate(DateTime.now().toISODate());
			}
		}
	}

	function handleContinueClicked() {
		if(amount > 0) {
			setShowAmountError(false);
			onContinueClicked({ Amount: amount, Date: withdrawalsThisMonth ? getMaxDate() : date });
		} else {
			setShowAmountError(true);
		}
	}

	function getMinDate() {
		let timeZA = DateTime.local().setZone("UTC+2");

		//if they've already withdrawn this month, they can only withdraw next month
		if(withdrawalsThisMonth) {
			timeZA = timeZA.plus({ month: 1 }).startOf('month');
		} else {
			//cut-off time is 10 in the morning, if we're after that, the min date must be the next day
			if(timeZA.hour >= 10) {
				timeZA = timeZA.plus({ days: 1 });
			}
		}
		
		return timeZA.toISODate();
	}

	function getMaxDate() {
		let monthEnd = DateTime.fromISO(state.MonthEndDate);
		let maxMonth = monthEnd.plus({ months: 1 }).endOf('month');

		if(withdrawalsThisMonth) {
			maxMonth = maxMonth.startOf('month');
		}

		return maxMonth.toISODate();
	}

	function getWithdrawalAllowed() {
		if(date) {
			let selectedDate = DateTime.fromISO(date);
			let existingDate = state.PendingMemberUserWithdrawals.find(d => {
				let withdrawalDate = DateTime.fromISO(d.WithdrawalDate);

				return withdrawalDate.month === selectedDate.month && withdrawalDate.year === selectedDate.year;
			});

			if(existingDate) {
				return false;
			}
		}

		return true;
	}

	const minDate = getMinDate();
	const maxDate = getMaxDate();

	return 	<Row>
				<Col>
					<Form>
						<Stack gap={3}>
							<Form.Group>
								<Form.Label>Amount</Form.Label>
								<NumericFormat 
									value={ amount } 
									customInput={ Form.Control } 
									onValueChange={ v => handleAmountUpdated(v.floatValue) }
									prefix="R" 
									thousandSeparator=" "
									decimalScale={2}
									className={ showAmountError ? "is-invalid" : null }
								/>
								{
									showAmountError &&
									<Form.Text className="text-danger">Please enter valid amount</Form.Text>
								}
							</Form.Group>
							<Form.Group>
								<Form.Label>Date</Form.Label>
								<Form.Control 
									disabled={ withdrawalsThisMonth ? true : null } 
									min={ minDate } 
									max={ maxDate } 
									type="date" 
									value={ withdrawalsThisMonth ? getMaxDate() : date } 
									onChange={ e => handleDateChanged(e.target.value) }
								/>
							</Form.Group>
							
							<Button className="mt-3" disabled={ getWithdrawalAllowed() === false } onClick={ handleContinueClicked }>Continue</Button>
							<Button onClick={ onCancelClicked }>Cancel</Button>
						</Stack>
					</Form>
				</Col>
			</Row>
}