import { Image, Row, Col } from "react-bootstrap";
import logo from "../../Images/logo.svg"

export default function Logo({ noMargin }) {
    return (
        <Row className={ `justify-content-center${ (noMargin ? "" : " mt-5") }` }>
            <Col xs={7} sm={5} md={3} lg={3} xl={2}>
                <Image src={ logo } className="w-100" fluid />
            </Col>
        </Row>
    );
}