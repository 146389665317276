import { useState } from "react";
import { Stack, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSessionStore } from "../../../Stores/SessionStore";
import Page from "../../Core/Page";
import Panel from "../../Core/Panel";
import Logo from "../../Core/Logo";
import SuccessModal from "../SuccessModal";
import ErrorModal from "../ErrorModal";
import IDNumberField from "../IDNumberField";

export default function RequestPasswordReset() {
	const store = useSessionStore();
	const navigate = useNavigate();

	const [idNumber, setIdNumber] = useState("");
	const [showIDError, setShowIDError] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	const [showError, setShowError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("There was an error requesting a password reset. Please try again.");
	const [requesting, setRequesting] = useState(false);

	async function requestPassword() {
		setRequesting(true);

		let result = await store.MemberUsersService.RequestPasswordReset(idNumber);

		setRequesting(false);
		
		if (result && result.Success) {			
			setShowSuccess(true);			
		} else {
			setShowError(true);
			setErrorMessage(result.Message);
			return false;
		}
	}
	
	function handleOKClicked() {
		if (idNumber && idNumber > 0 && idNumber.toString().length === 13) {
			requestPassword();
		} else {
			setShowIDError(true);
		}
	}

	function handleIDNumberChanged(newIDNumber) {
		setIdNumber(newIDNumber);
		setShowIDError(false);
	}

	return (
		<Page bgBlue>
			<Logo />
			<Stack className="justify-content-start h-50">
				<Panel>
					<Form>
						<Stack gap={3}>
							<IDNumberField value={idNumber} onChange={handleIDNumberChanged} showIDError={showIDError} setShowIDError={setShowIDError}/>
							<Button className="mt-3" onClick={() => handleOKClicked()} disabled={requesting || showIDError}>{requesting ? "Requesting..." : "Okay"}</Button>
							<Button onClick={ () => navigate("/") }>Cancel</Button>
						</Stack>
					</Form>
				</Panel>
			</Stack>
			<SuccessModal show={showSuccess} onClose={() => {setShowSuccess(false); navigate("/login")}} message={"Request sent successfully."}/>
			<ErrorModal show={showError} onClose={() => setShowError(false)} message={errorMessage}>{errorMessage}</ErrorModal>
		</Page>
	)       
}