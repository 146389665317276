import { useState } from "react";
import Page from "../Core/Page";
import ConsentBox from "./ConsentBox";
import UpdateDetailsModal from "./UpdateDetailsModal";
import { useSessionStore } from "../../Stores/SessionStore";

export default function UpdateDetail({ update, children, detail, validate, disableConsent }) {
	const store = useSessionStore();

	const [updating, setUpdating] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [error, setError] = useState(false);

	async function handleUpdateClicked() {
		if(!validate()) {
			return;
		}

		setUpdating(true);
		
		let result = await update();
		
		setError(!result.Success);
		setShowModal(true);

		if(result.Success) {
			await store.MemberUserStore.LoadMemberUserState(true);
			setUpdating(false);
		} else {
			setUpdating(false);
		}
	}

	return 	<Page title={ `Edit ${detail}` }>
				{
					children
				}
				<ConsentBox isBusy={ updating } detail={ detail } onUpdateClicked={ handleUpdateClicked } disabled={disableConsent}/>
				<UpdateDetailsModal detail={ detail } error={ error } show={ showModal } onClose={ () => setShowModal(false) } />
			</Page>
}