/**
 * @typedef RequestResult
 * @property {boolean} Success
 * @property {string} Message
 * @property {number} ResultCode
 * @property {T} Data
 * @template T
 */

/**
 * @typedef EnumerableRequestResult
 * @property {boolean} Success
 * @property {string} Message
 * @property {number} ResultCode
 * @property {Array<T>} Data
 * @property {number} Total
 * @template T
 */

/**
 * @typedef DownloadFile
 * @property {string} FileName
 * @property {string} FileContents
 * @property {string} MimeType
 */

/**
 * @typedef APIBase
 */
 export default class APIBase {
	APIUrl;
	SessionStore;

	constructor(apiUrl, sessionStore) {
		this.APIUrl = apiUrl;
		this.SessionStore = sessionStore;
	}
	
	async SendRequest(url, method, body) {
		var session = this.SessionStore ? this.SessionStore.Session : null;

		try {
			var response = await fetch(url, {
				method: method,
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + (session ? session.BearerToken : "")
				},
				body: body ? JSON.stringify(body) : null
			});
			
			if(response.status === 401) {
				this.SessionStore.Logout();				
			} else if(response.status === 500) {
				return { Success: false, StatusCode: 500, Message: "An internal error occurred on the server, please contact support." };
			} else {
				return await response.json();
			}            
		}
		catch(ex) {
			
		}

		return { Success: false, StatusCode: 500, Message: "The server did not respond to the request." };
	}

	async Post(endpoint, body) {
		return await this.SendRequest(this.APIUrl + "/" + endpoint, "POST", body);
	}

	async Get(endpoint) {
		return await this.SendRequest(this.APIUrl + "/" + endpoint, "GET", null);
	}
}