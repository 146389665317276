import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";
import MemberUsersService from "../Services/MemberUsersService";
import NotificationsService from "../Services/NotificationsService";
import { MemberUserStore } from "./MemberUserStore";
import { NotificationStore } from "./NotificationStore"
export const Tabs = {
	Home: 1, UpdateDetails: 2, More: 3
}

/**
 * @typedef Session
 * @type {object}
 * @property {string} BearerToken - The JWT Token used to authenticate the client.
 * @property { import('../Services/MemberUsersService').User } User - The logged in user
 */

/**
 * @typedef Config
 * @type {object}
 * @property {string} apiUrl - The URL to the application's backend API.
 */

/** @type {SessionStore} */
var SessionStoreInstance = null;

const SessionStoreContext = createContext(null);

/**
 * @typedef SessionStore
 * @type {SessionStore}
*/
export class SessionStore {
	/**@type {Config} */ Config = null;
	/**@type {Session} */ Session = null;
	/**@type {UsersService} */ UsersService = null;

	RefreshingLogin = false;

	ReloginCallbacks = [];
	RefreshCallbacks = [];

	LoggingIn = false;

	CurrentTab = Tabs.Home;

	/**@type {import("./MemberUserStore").MemberUserStore} */ MemberUserStore = null;

	/**@param {Config} config */
	constructor(config) {
		this.Config = config;

		//attempt to initialise the session from local storage
		this.GetStoredSession();

		//initialise services
		this.MemberUsersService = new MemberUsersService(config.apiUrl, this);
		this.NotificationsService = new NotificationsService(config.apiUrl, this);

		this.MemberUserStore = new MemberUserStore(this.MemberUsersService);
		this.NotificationStore = new NotificationStore(this.NotificationsService);
		
		makeAutoObservable(this);
	}

	GetStoredSession() {
		var session = localStorage.getItem("Session");

		if(session) {
			this.Session = JSON.parse(session);
		} else {
			this.Session = null;
		}
	}

	setCurrentTab(tab) {
		this.CurrentTab = tab;
	}

	get UserIsLoggedIn () {
		return this.Session && this.Session.User;
	}

	async SetLoggingIn(loggingIn) {
		runInAction(() => { this.LoggingIn = loggingIn; });
	}

	async Login(idNumber, password) {
		this.SetLoggingIn(true);
		var result = await this.MemberUsersService.Login(idNumber, password);
		this.SetLoggingIn(false);

		if(result.Success) {
			localStorage.setItem("Session", JSON.stringify(result.Data));
			runInAction(() => { this.Session = result.Data; });
		}

		return result;
	}

	Logout () {
		localStorage.clear();
		this.Session = null;
		this.MemberUserStore.MemberUserState = null;
	}
}

export function SessionStoreProvider(props) {
	if(SessionStoreInstance === null) {
		SessionStoreInstance = new SessionStore(props.config);
	}

	return <SessionStoreContext.Provider value={ SessionStoreInstance }>{ props.children }</SessionStoreContext.Provider>
}

/**
 * @returns {SessionStore}
 */
export function useSessionStore() {
	const context = useContext(SessionStoreContext);

	return context;
}