import { Button, Modal, Stack } from "react-bootstrap";

export default function MessageModal({ message, show, onClose, showCancel }) {
	return 	<Modal show={ show } centered>
				<Modal.Body className="py-5">
					<div className="text-primary text-center pb-5 px-2">
						{ message }
					</div>
					<Stack gap={3}>
						<Button onClick={ () => onClose(true) }>Okay</Button>
						{
							showCancel &&
							<Button onClick={ () => onClose(false) }>Cancel</Button>
						}
					</Stack>
				</Modal.Body>
			</Modal>
}