import { useState } from "react";
import { Button, Form } from "react-bootstrap";

export default function ConsentBox({ onUpdateClicked, isBusy, detail, disabled }) {
	const [agree, setAgree] = useState(false);

	return 	<div className="d-flex flex-column justify-content-end flex-grow-1 align-items-center mb-5 px-4">
				<Form.Check 
					disabled={ isBusy || disabled}
					value={ agree } 
					onChange={ e => setAgree(e.target.checked) } 
					type="checkbox" 
					label={ `I consent to Durban Municipal Thrift Association updating my ${detail}.` }
				/>
				<Button 
					disabled={ agree === false || isBusy || disabled} 
					className="d-block w-100 mt-4"
					onClick={ onUpdateClicked }>
						{ isBusy ? "Updating..." : "Update" }
				</Button>
			</div>
}