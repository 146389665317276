import { DateTime } from "luxon";
import { Col, Row } from "react-bootstrap";
import { useSessionStore } from "../../Stores/SessionStore";

function TradingTime({ day, time }) {
	return 	<Row style={{ fontSize:"12px" }}>
				<Col>{ day }</Col>
				<Col className="text-end">{ time }</Col>
			</Row>
}

export default function WithdrawalInfo() {
	const store = useSessionStore();

	const officeClosedFrom = DateTime.fromFormat(store.MemberUserStore.MemberUserState.OfficeClosedFrom, "yyyy-MM-dd");
	const officeClosedTo = DateTime.fromFormat(store.MemberUserStore.MemberUserState.OfficeClosedTo, "yyyy-MM-dd");

	return 	<Row className="fixed-bottom bg-white p-3">
				<Col>
					<div className="my-2" style={{ fontSize:"12px" }}>Withdrawals submitted after the cut-off times will be processed on the next business day.</div>
					{
						DateTime.now().month === 12 &&
						<div className="my-2" style={{ fontSize:"12px" }}>
							<span>Please note: The DMTF offices are closed between </span>
							<span>{ officeClosedFrom.day.toString().padStart(2, "0") } { officeClosedFrom.monthLong } and </span> 
							<span>{ officeClosedTo.day.toString().padStart(2, "0") } { officeClosedTo.monthLong }. </span>
							<span>Any withdrawals made during this period will be processed on the first working day of the new year.</span>
						</div>
					}
					<div>
						<div className="text-primary">Withdrawal cut-off times</div>
						<TradingTime day="Monday to Friday" time="10:00" />
						<TradingTime day="Saturday & Sunday" time="Closed" />
						<TradingTime day="Public Holidays" time="Closed" />
					</div>
				</Col>
			</Row>
}