import { useEffect, useState } from "react";
import Page from "../Core/Page";

export default function TermsAndConditions() {

    const [htmlFileString, setHtmlFileString] = useState();

    async function fetchHtml() {
        setHtmlFileString(await (await fetch("terms-and-conditions.html")).text());
    }

    useEffect(() => {
        fetchHtml();
    }, []);

    return 	<Page title="Terms and conditions">
                <div className="small p-3" dangerouslySetInnerHTML={{__html: htmlFileString}} />
            </Page>
}