import { Button, Col, Modal, Row, Stack } from "react-bootstrap";
import { useSessionStore } from "../../Stores/SessionStore";
import { useEffect, useState } from "react";
import Currency from "../Core/Currency";
import { DateTime } from "luxon";

export default function ConfirmWithdrawalDeleteModal({ show, onClose, withdrawal }) {
	const store = useSessionStore();

	const [deleting, setDeleting] = useState(false);
	const [deleted, setDeleted] = useState(false);
	const [error, setError] = useState(null);

	async function handleYesClicked() {
		setError(null);
		setDeleting(true);

		let result = await store.MemberUsersService.DeleteWithdrawal(withdrawal.TransactionId);

		setDeleting(false);

		if(result && result.Success) {
			store.MemberUserStore.LoadMemberUserState(true);
			setDeleted(true);
		} else {
			if(result && result.Message) {
				setError(result.Message);
			} else {
				setError("Please contact DMTF support.");
			}
		}
	}

	useEffect(() => {
		setDeleted(false);
	}, []);

	return 	<Modal show={ show } centered>
				<Modal.Body className="py-5">
					{
						withdrawal &&
						<Row className="my-4 px-0 mx-0">
							<Col className="text-end m-0 p-0 overflow-hidden text-nowrap">							
								<div><small>Withdrawal Amount</small></div>
								<div><small>Date</small></div>
							</Col>
							<Col xs={1} className="d-flex justify-content-center mx-0 px-0">
								<div style={{ width: "1px", height: "100%", background: "linear-gradient(#0166c2, #0166c2) no-repeat center/2px 100%" }}></div>
							</Col>
							<Col className="p-0 overflow-hidden text-nowrap">							
								<div><small><Currency amount={ withdrawal.WithdrawalAmount } /></small></div>
								<div><small>{ DateTime.fromISO(withdrawal.WithdrawalDate).toFormat("dd/MM/yyyy") }</small></div>
							</Col>
						</Row>
					}
					
					<div className="text-center pb-5 px-2">
					{
						!deleted && 
						<>
							{ 
								!error &&
								<span className="text-primary">Are you sure you want to cancel this withdrawal?</span>
							}
							{
								error &&
								<span className="text-danger">An error occurred attempting to cancel the withdrawal: { error }</span>
							}
						</>
					}
					{
						deleted && <span>Your withdrawal has been cancelled.</span>
					}
					</div>
					<Stack gap={3}>
						{
							!deleted &&
							<>
								<Button disabled={ deleting } variant="danger" onClick={ handleYesClicked }>{ (deleting ? "Cancelling..." : "Yes, cancel this withdrawal") }</Button>
								<Button disabled={ deleting } onClick={ () => onClose(false) }>Cancel</Button>
							</>
						}
						{
							deleted &&
							<>
								<Button onClick={ () => onClose(true) }>Okay</Button>
							</>
						}
					</Stack>
				</Modal.Body>
			</Modal>
}