import { observer } from "mobx-react-lite";
import { Navigate, Route, Routes } from "react-router-dom";
import Landing from "./Landing";
import Login from "./Login/Login";
import Register from "./Register/Register";
import RequestPasswordReset from "./ForgotPassword/RequestPasswordReset";
import ResetPassword from "./ForgotPassword/ResetPassword";

const Auth = observer(function Auth() {

	return 	<Routes>
				<Route path="/" element={ <Landing /> }></Route>
				<Route path="/login" element={ <Login /> }></Route>
				<Route path="/register" element={ <Register /> }></Route>
				<Route path="/request-password" element={ <RequestPasswordReset /> }></Route>
				<Route path="/reset-password/:token" element={ <ResetPassword /> }></Route>
				<Route path="*" element={<Navigate to="/" />} />
			</Routes>	
});

export default Auth;