import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-thin-svg-icons";
import { useNavigate } from "react-router-dom";

export default function Page({ children, title, bgBlue }) {
	const navigate = useNavigate();

	function handleBackClicked() {
		navigate(-1);
	}

	return 	<>
				{
					title &&
					<Row className="py-1 fs-5 border-bottom border-primary bg-white text-primary">
						<Col>
							<FontAwesomeIcon icon={ faArrowLeft } fixedWidth onClick={ handleBackClicked }/>
							<span className="ms-2">{title}</span>
						</Col>
					</Row>
				}
				
				<Row className={ `d-flex flex-grow-1 ${ bgBlue ? "bg-primary" : "bg-white" }` }>
					{ children }
				</Row>
			</>
}