import { useState } from "react";
import { Form } from "react-bootstrap";

export default function IDNumberField({value, onChange}) {

	return (
		<Form.Group>
			<Form.Label>ID Number</Form.Label>
			{
				value.length !== 13 &&
				<Form.Text className="text-danger ms-1">{"(Invalid ID Number)"}</Form.Text>
			}
			<Form.Control          
				type="number"
				value={ value && value.toString() }
				onChange={ (e) => {onChange(e.target.value)} }
			/>
		</Form.Group>
	)
}