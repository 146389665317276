import { Button, Modal } from "react-bootstrap";

export default function UpdateDetailsModal({ show, onClose, error, detail }) {
	return 	<Modal show={ show } centered>
				<Modal.Body className="py-5 d-flex flex-column align-items-center">
					<div className={ `${ error ? "text-danger " : ""}text-center pb-5 px-2` }>
						{ error ? `An error occurred attempting to update your ${detail}. Please contact DMTA support.` : 
									`Your ${detail} was successfully updated.` }
					</div>
					<Button className="w-100" style={{ maxWidth: "15em" }} onClick={ () => onClose() }>Okay</Button>
				</Modal.Body>
			</Modal>
}