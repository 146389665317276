import { DateTime } from "luxon";
import { useSessionStore } from "../../Stores/SessionStore";
import Currency from "../Core/Currency";
import Panel from "../Core/Panel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import ConfirmWithdrawalDeleteModal from "./ConfirmWithdrawalDeleteModal";
import { useState } from "react";

export default function MemberBalanceSummary() {
	const store = useSessionStore();
	const state = store.MemberUserStore.MemberUserState;
	const pendingWithdrawal = (state.PendingMemberUserWithdrawals && state.PendingMemberUserWithdrawals.length > 0 && state.PendingMemberUserWithdrawals[0]) ?
								state.PendingMemberUserWithdrawals[0] : null;
	
	const [showDeleteWithdrawalModal, setShowWithdrawalDeleteModal] = useState(false);

	function getPendingWithdrawalDate() {
		if(pendingWithdrawal) {
			let date = DateTime.fromISO(pendingWithdrawal.WithdrawalDate);

			return date.toFormat("dd/MM/yyyy");
		}

		return "";
	}

	function canCancelWithdrawal() {
		let timeZA = DateTime.local().setZone("UTC+2");
		let transactionDate = DateTime.fromISO(pendingWithdrawal.WithdrawalDate);

		if(timeZA.year === transactionDate.year && timeZA.month === transactionDate.month && timeZA.day === transactionDate.day) {
			if(timeZA.hour >= 10) {
				return false;
			}
		}

		return true;
	}

	return 	<Panel>
				<div className="d-flex flex-column align-items-center text-primary text-center">
					{
						state && !store.MemberUserStore.RefreshingState &&
						<>
							<h3 className="text-uppercase">Hello {store.Session.User.FirstName.trim()}!</h3>
							<div>Your total balance is</div>
							<h2><Currency amount={ state.BalanceSummary.CurrentBalance } /></h2>
							<div>Your max withdrawal amount is</div>
							<h2><Currency amount={ state.BalanceSummary.MaxTransfer } /></h2>
							<div>Your free balance is</div>
							<h2><Currency amount={ state.BalanceSummary.FreeBalance } /></h2>
							{
								pendingWithdrawal &&
								<>
									<div className="text-danger" style={{ fontSize:"12px" }}>
										Pending withdrawal of <Currency amount={ pendingWithdrawal.WithdrawalAmount } /> 
										<span> { getPendingWithdrawalDate() }</span>
									</div>
									{
										canCancelWithdrawal() &&
										<Button className="mt-3" variant="danger" onClick={ () => setShowWithdrawalDeleteModal(true) }>Cancel withdrawal</Button>
									}
								</>
							}
						</>
					}
					{
						(!state || store.MemberUserStore.RefreshingState) &&
						<span className="text-primary">
							<FontAwesomeIcon size="2x" icon={ faRefresh } spin></FontAwesomeIcon>
						</span>
					}
				</div>
				<ConfirmWithdrawalDeleteModal 
					show={ showDeleteWithdrawalModal }
					onClose={ () => setShowWithdrawalDeleteModal(false) }
					withdrawal={ pendingWithdrawal }
				/>
			</Panel>
}