import { Col, Row, Stack } from "react-bootstrap";
import { useSessionStore } from "../../Stores/SessionStore";
import Currency from "../Core/Currency";

function BalanceSummaryRow({ label, amount }) {
	return 	<Row className="text-white d-flex align-items-center">
				<Col xs={8}>{ label }</Col>
				<Col className="text-end"><Currency amount={ amount } /></Col>
			</Row>
}

export default function MemberBalances() {
	const store = useSessionStore();

	const balanceSummary = store.MemberUserStore.MemberUserState?.BalanceSummary;

	return 	<Row className="user-select-none">
				<Col>
					<Stack direction="vertical" gap={2}>
						<BalanceSummaryRow label="Balance" amount={ balanceSummary?.CurrentBalance } />
						<BalanceSummaryRow label="Max Withdrawal Amount" amount={ balanceSummary?.MaxTransfer } />
						<BalanceSummaryRow label="Free Balance" amount={ balanceSummary?.FreeBalance } />
					</Stack>
				</Col>
			</Row>
}