import { useParams } from "react-router-dom";
import { useSessionStore } from "../../Stores/SessionStore";
import Page from "../Core/Page";
import { useEffect, useState } from "react";
import Spinner from "../Core/Spinner";
import { Col, Row } from "react-bootstrap";

export default function Notification() {
	const store = useSessionStore();
	const params = useParams();

	const [notification, setNotification] = useState(null);

	async function getNotification(id) {
		let result = await store.NotificationsService.GetMemberUserNotification(id);

		if(result && result.Success) {
			//bootstrap already puts spaces between <p> tags, but the quilljs plugin creating the notification body html creates <p></br></p> between
			//each paragraph, leaving too large a gap between paragraphs, so we'll remove them here to improve the message display
			result.Data.Body = result.Data.Body.replace(/<p><br><\/p>/gm, "");

			setNotification(result.Data);

			store.NotificationsService.ReadMemberUserNotification(id);
		}
	}

	useEffect(() => {
		if(params.id) {
			getNotification(params.id);
		}
	}, []);

	return 	<Page title="Notification">
				<div>
				{
					notification &&
					<Row className="pt-3">
						<Col>
							<h5 className="text-primary">{ notification.Subject }</h5>
							<div dangerouslySetInnerHTML={{ __html: notification.Body }}></div>
						</Col>
					</Row>
				}
				{
					!notification &&
					<Spinner variant="primary" />
				}
				</div>
			</Page>
}