import { Col, Row } from "react-bootstrap";
import Panel from "../Core/Panel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines, faWallet } from "@fortawesome/free-solid-svg-icons";

function Action({ icon, text, url, onClick }) {
	return 	<Col>
				<a onClick={ onClick } href={ url } title={ text } className="d-flex flex-column justify-content-center text-center text-decoration-none text-primary">
					<FontAwesomeIcon size="3x" icon={ icon }></FontAwesomeIcon>
					<div className="pt-2">{ text }</div>
				</a>
			</Col>	
}

export default function MemberActions() {	
	return 	<Panel>
				<Row className="py-1">
					<Action 
						icon={ faWallet } 
						text="Make a Withdrawal" 
						url="#/Withdrawal"
					/>
					<Col xs={1} className="d-flex justify-content-center">
						<div style={{ width: "1px", height: "100%", background: "linear-gradient(#0166c2, #0166c2) no-repeat center/2px 100%" }}></div>
					</Col>
					<Action icon={ faFileLines } text="Download Statement" url="#/Statements"></Action>
				</Row>
			</Panel>
}