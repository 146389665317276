import { Col, Row, Stack } from "react-bootstrap";
import Page from "../Core/Page";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/pro-thin-svg-icons";
import { useState } from "react";
import { useSessionStore } from "../../Stores/SessionStore";
import { saveAs } from "file-saver";
import StatementDownloadedModal from "./StatementDownloadedModal";

function Statement({ title, onClick, downloading }) {
	function handleClick() {
		if(downloading === false) {
			onClick();
		}
	}

	return 	<Row className="user-select-none border-bottom text-primary py-2 mx-1" onClick={handleClick}>
				<Col className="mx-n1">
					{title}
				</Col>
				<Col className="d-flex justify-content-end text-end align-items-center">
					{
						downloading &&
						<FontAwesomeIcon icon={ faRefresh } spin fixedWidth />
					}
				</Col>
			</Row>
}

export default function Statements() {
	const store = useSessionStore();

	const [showDownloadedModal, setShowDownloadedModal] = useState(false);
	const [multipleDownloads, setMultipleDownloads] = useState(false);

	const [downloadingCurrentYear, setDownloadingCurrentYear] = useState(false);
	const [downloadingPreviousYear, setDownloadingPreviousYear] = useState(false);
	const [downloadingBothYears, setDownloadingBothYears] = useState(false);

	function saveFile(downloadFile) {
		const byteString = window.atob(downloadFile.FileContents);
		const arrayBuffer = new ArrayBuffer(byteString.length);
		const int8Array = new Uint8Array(arrayBuffer);
		for (let i = 0; i < byteString.length; i++) {
			int8Array[i] = byteString.charCodeAt(i);
		}

		var blob = new Blob([int8Array], {type: downloadFile.MimeType});
		saveAs(blob, downloadFile.FileName);
	}

	async function handleCurrentYearClicked() {
		setDownloadingCurrentYear(true);
		let result = await store.MemberUsersService.GetCurrentYearMemberStatement();
		setDownloadingCurrentYear(false);

		if(result && result.Success) {
			setShowDownloadedModal(true);
			saveFile(result.Data);
		}
	}

	async function handlePreviousYearClicked() {
		setDownloadingPreviousYear(true);
		let result = await store.MemberUsersService.GetPreviousYearMemberStatement();
		setDownloadingPreviousYear(false);

		if(result && result.Success) {
			setShowDownloadedModal(true);
			saveFile(result.Data);
		}
	}

	async function handleBothClicked() {
		setMultipleDownloads(true);
		setDownloadingBothYears(true);

		await handleCurrentYearClicked();
		await handlePreviousYearClicked();

		setDownloadingBothYears(false);
		setShowDownloadedModal(true);
	}

	function handleModalClosed() {
		setShowDownloadedModal(false);
		setMultipleDownloads(false);
	}

	return 	<Page title="Statements">
				<Row>
					<Col>
						<Stack>
							<Statement title="Current Year" downloading={ downloadingCurrentYear && !downloadingBothYears } onClick={ handleCurrentYearClicked }/>
							<Statement title="Previous Year" downloading={ downloadingPreviousYear && !downloadingBothYears } onClick={ handlePreviousYearClicked }/>
							<Statement title="Both Years" downloading={ downloadingBothYears } onClick={ handleBothClicked }/>
						</Stack>
					</Col>
				</Row>
				<StatementDownloadedModal show={ showDownloadedModal } onClose={ handleModalClosed } multiple={ multipleDownloads } />
			</Page>
}