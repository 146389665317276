import { Button, Modal } from "react-bootstrap";

export default function WithdrawalErrorModal({ show, onClose, message }) {
	return 	<Modal show={ show } centered>
				<Modal.Body className="py-5 d-flex flex-column align-items-center">
					<div className="text-danger text-center pb-5 px-2">
						{ message }
					</div>
					<Button className="w-100" style={{ maxWidth: "15em" }} onClick={ () => onClose() }>Okay</Button>
				</Modal.Body>
			</Modal>
}