import { Col, Row, Stack } from "react-bootstrap";
import { useSessionStore } from "../../Stores/SessionStore";

export default function WithdrawalStats() {
	const store = useSessionStore();
	const state = store.MemberUserStore.MemberUserState
	
	return 	<Row>
				<Col>
					<Stack gap={ 1 } className="text-white user-select-none">
						<div>{ state.TotalPendingWithdrawals } Withdrawals pending</div>
						<div>{ state.TotalWithdrawalsThisMonth } Withdrawals this month</div>
						<div>{ state.TotalWithdrawalsThisYear } Withdrawals this year</div>
					</Stack>
				</Col>
			</Row>
}