import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TabPage from "../Core/TabPage";
import { faArrowRightFromBracket, faBell } from "@fortawesome/pro-thin-svg-icons";
import { Col, Row, Stack } from "react-bootstrap";
import MemberActions from "./MemberActions";
import MemberBalanceSummary from "./MemberBalanceSummary";
import { observer } from "mobx-react-lite";
import { Tabs, useSessionStore } from "../../Stores/SessionStore";
import { useEffect, useState } from "react";
import ConfirmLogoutModal from "./ConfirmLogoutModal";
import { useNavigate } from "react-router-dom";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import Logo from "../Core/Logo";

const Home = observer(function Home() {
	const store = useSessionStore();
	const nagivate = useNavigate();

	const [confirmingLogout, setConfirmingLogout] = useState(false);
	const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);

	const logout = <span className="d-flex flex-row align-items-center mt-1" onClick={ () => setConfirmingLogout(true) }><FontAwesomeIcon icon={ faArrowRightFromBracket } /><div className="ps-2 h5 d-flex align-items-center mb-0">Logout</div></span>;
	const notifications = 	<span onClick={ () => nagivate("notifications") }>
								<span className="fa-layers">
									<FontAwesomeIcon icon={ faBell }></FontAwesomeIcon>
									{
										unreadNotificationsCount !== 0 &&
										<FontAwesomeIcon transform={{ x: -6, y: 5, size: 6 }} className="text-danger" icon={ faCircle }></FontAwesomeIcon>
									}
								</span>
							</span>;

	function handleLogoutConfirmed(logout) {
		setConfirmingLogout(false);
		
		if(logout) {
			store.Logout();
		}
	}

	async function getUnreadNotificationCount() {
		let result = await store.NotificationsService.GetUnreadNotificationCount();

		if(result && result.Success) {
			setUnreadNotificationsCount(result.Data);
		}
	}

	useEffect(() => {
		store.setCurrentTab(Tabs.Home);
		getUnreadNotificationCount();		
	}, []);

	return 	<TabPage leftIcon={ logout } rightIcon={ notifications }>
				<Row>
					<Col>
						<Stack gap={3}>
							<Row>
								<Col xs={8} className="offset-2">
									<Logo noMargin />
								</Col>
							</Row>
							<MemberBalanceSummary />
							{
								store.MemberUserStore.MemberUserState && !store.MemberUserStore.RefreshingState &&
								<MemberActions />
							}
						</Stack>
					</Col>
				</Row>
				<ConfirmLogoutModal show={ confirmingLogout } onClose={ handleLogoutConfirmed } />
			</TabPage>
});

export default Home;