import Page from "../Core/Page";
import { Accordion } from "react-bootstrap";

function FAQ({number, header, children}) {
    return (
        <Accordion.Item eventKey={number}>
            <Accordion.Header><span className="text-primary">{header}</span></Accordion.Header>
            <Accordion.Body>
                { children }
            </Accordion.Body>
        </Accordion.Item>
    )
}

export default function FAQS() {
    
	let faqs = require("./faq.json");

	return 	<Page title="FAQ's">				
				<Accordion flush>
                    { faqs.map((faq, index) => <FAQ key={index} number={index} header={faq.header}>{faq.body}</FAQ>) }              
                </Accordion>
			</Page>
}