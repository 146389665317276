import { Button, Modal, Stack } from "react-bootstrap";

export default function ConfirmLogoutModal({ show, onClose }) {
	return 	<Modal show={ show } centered>
				<Modal.Body className="py-5">
					<div className="text-primary text-center pb-5 px-2">
						Are you sure you wish to logout?
					</div>
					<Stack gap={3}>
						<Button onClick={ () => onClose(true) }>Okay</Button>
						<Button onClick={ () => onClose(false) }>Cancel</Button>
					</Stack>
				</Modal.Body>
			</Modal>
}