import { useState } from "react"
import Panel from "../../Core/Panel";
import AmountAndTime from "./AmountAndTime";
import Confirmation from "./Confirmation";
import Complete from "./Complete";
import { useSessionStore } from "../../../Stores/SessionStore";
import { useNavigate } from "react-router-dom";
import WithdrawalErrorModal from "./WithdrawalErrorModal";

export const Steps = {
	AmountAndTime: 1,
	Confirmation: 2,
	Complete: 3
}

export default function WithdrawalSteps({ onStepChange }) {
	const store = useSessionStore();
	const navigate = useNavigate();

	const [withdrawalData, setWithdrawalData] = useState(null);
	const [currentStep, setCurrentStep] = useState(Steps.AmountAndTime);
	const [creatingWithdrawal, setCreatingWithdrawal] = useState(false);

	const [showErrorModal, setShowErroModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	function handleContinueClicked(withdrawal) {
		setWithdrawalData(withdrawal);
		setStep(Steps.Confirmation);
	}

	async function handleConfirmClicked() {
		setCreatingWithdrawal(true);

		let result = await store.MemberUsersService.CreateWithdrawal(withdrawalData.Amount, withdrawalData.Date);

		if(result && result.Success) {
			setCreatingWithdrawal(false);
			setStep(Steps.Complete);
		} else {
			if(result && result.ResultCode === 401) {
				store.Logout();
				return;
			}

			setErrorMessage(result.Message);
			setShowErroModal(true);
			setCreatingWithdrawal(false);
		}
	}

	function setStep(step) {
		setCurrentStep(step);
		onStepChange(step);
	}

	return 	<Panel>
				{
					currentStep === Steps.AmountAndTime &&
					<AmountAndTime 
						onCancelClicked={ () => navigate("/") } 
						onContinueClicked={ handleContinueClicked }
						withdrawalData={ withdrawalData }
					/>
				}
				{
					currentStep === Steps.Confirmation &&
					<Confirmation
						withdrawalData={ withdrawalData } 
						creatingWithdrawal={ creatingWithdrawal }  
						onCancelClicked={ () => setStep(Steps.AmountAndTime) }
						onConfirmClicked={ handleConfirmClicked }
					/>
				}
				{
					currentStep === Steps.Complete &&
					<Complete onOkClicked={ () => navigate("/") } />
				}
				<WithdrawalErrorModal 
					message={ errorMessage } 
					onClose={ () => setShowErroModal(false) }
					show={ showErrorModal }
				/>
			</Panel>
}