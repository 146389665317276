import { Button, Col, Row, Stack } from "react-bootstrap";
import { useSessionStore } from "../../../Stores/SessionStore";
import Currency from "../../Core/Currency";
import { DateTime } from "luxon";

export default function Confirmation({ onConfirmClicked, onCancelClicked, creatingWithdrawal, withdrawalData }) {
	const store = useSessionStore();
	const bankAccount = store.MemberUserStore.MemberUserState.BankDetails;

	return 	<Stack gap={3}>
				<Row className="my-4 px-0 mx-0">
					<Col className="text-end m-0 p-0 overflow-hidden text-nowrap">
						<div><small>Payee</small></div>
						<div><small>Bank Name</small></div>
						<div><small>Acc no. (last 4 digits)</small></div>
						<div><small>Withdrawal Amount</small></div>
						<div><small>Date</small></div>
					</Col>
					<Col xs={1} className="d-flex justify-content-center mx-0 px-0">
						<div style={{ width: "1px", height: "100%", background: "linear-gradient(#0166c2, #0166c2) no-repeat center/2px 100%" }}></div>
					</Col>
					<Col className="p-0 overflow-hidden text-nowrap">
						<div><small>{ bankAccount.Payee }</small></div>
						<div><small>{ bankAccount.BankName }</small></div>
						<div><small>xxxx xxxx { bankAccount.AccountNumber }</small></div>
						<div><small><Currency amount={ withdrawalData.Amount } /></small></div>
						<div><small>{ DateTime.fromISO(withdrawalData.Date).toFormat("dd/MM/yyyy") }</small></div>
					</Col>
				</Row>
				<Button disabled={ creatingWithdrawal } onClick={ onConfirmClicked }>{ creatingWithdrawal ? "Confirming..." : "Confirm Withdrawal" }</Button>
				<Button disabled={ creatingWithdrawal } onClick={ onCancelClicked }>Cancel</Button>
			</Stack> 	
	
}