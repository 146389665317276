import { useState } from "react";
import { Stack, Button, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useSessionStore } from "../../../Stores/SessionStore";
import Page from "../../Core/Page";
import Panel from "../../Core/Panel";
import Logo from "../../Core/Logo";
import ErrorModal from "../ErrorModal";
import SuccessModal from "../SuccessModal";
import PasswordField from "../PasswordField";
import IDNumberField from "../IDNumberField";
import PasswordChecklist from "../Register/PasswordChecklist";

export default function ResetPassword() {
	const store = useSessionStore();
	const navigate = useNavigate();
	const params = useParams();

	const [IdNumber, setIdNumber] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [showSuccess, setShowSuccess] = useState(false);
	const [showError, setShowError] = useState(false);
	const [resetErrorMessage, setResetErrorMessage] = useState("There was an error reseeting your password. Please try again.");
	const [successMessage, setSuccessMessage] = useState("Password reset successfully. Please login with your new password.");
	const [resetting, setResetting] = useState(false);

	async function resetPassword() {
		setResetting(true);

		let result = await store.MemberUsersService.ResetPassword(params.token, password, confirmPassword);

		setResetting(false);

		if (result && result.Success) {
			if (result.Message !== "") setSuccessMessage(result.Message);

			setShowSuccess(true);
		} else {
			setShowError(true);
			setResetErrorMessage(result.Message);

			return false;
		}
	}

	function handleResetClicked() {
		if (IdNumber && IdNumber > 0 && IdNumber.toString().length === 13 && password.length > 0) {
			resetPassword();
		}
	}

	return (
	<Page bgBlue>
			<Logo />
			<Stack className="justify-content-start h-50">
				<Panel>
					<Form>
						<Stack gap={3}>
							<IDNumberField value={IdNumber} onChange={setIdNumber}/>
							<PasswordField
								label="Password"
								value={password}
								showPasswordError={false}
								onChange={setPassword}
							/>
							<PasswordField
								label="Repeat Password"
								value={confirmPassword}
								passwordError={password !== confirmPassword ? "(Password doesn't match)" : null}
								onChange={setConfirmPassword}
							/>
							<PasswordChecklist password={password}/>
							<Button
								className="mt-3"
								onClick={() => handleResetClicked()}
								disabled={resetting || password.length === 0 || password != confirmPassword || !IdNumber || IdNumber.length !== 13}
							>
								{resetting ? "Resetting..." : "Reset"}
							</Button>
							<Button onClick={ () => navigate("/") }>Cancel</Button>
						</Stack>
					</Form>
				</Panel>
			</Stack>
			<SuccessModal show={showSuccess} onClose={() => {setShowSuccess(false); navigate("/login")}} message={successMessage}/>
			<ErrorModal show={showError} onClose={() => setShowError(false)} message={resetErrorMessage} />
		</Page>
    )
}