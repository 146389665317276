import { observer } from "mobx-react-lite";
import { Navigate, Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import { useSessionStore } from "../Stores/SessionStore";
import Home from "./Home/Home";
import Statements from "./Statements/Statements";
import Withdrawal from "./Withdrawal/Withdrawal";
import UpdateDetails from "./UpdateDetails/UpdateDetails";
import UpdateAddress from "./UpdateDetails/UpdateAddress";
import UpdateEmailAddress from "./UpdateDetails/UpdateEmailAddress";
import UpdatePhoneNumber from "./UpdateDetails/UpdatePhoneNumber";
import Notifications from "./Notifications/Notifications";
import More from "./More/More";
import FAQS from "./More/FAQS";
import ContactUs from "./More/ContactUs";
import TermsAndConditions from "./More/TermsAndConditions";
import PrivacyNotice from "./More/PrivacyNotice";
import Notification from "./Notifications/Notification";
import Spinner from "./Core/Spinner";

const Main = observer(function Main() {
	const store = useSessionStore();

	useEffect(() => {
		async function loadMemberState() {
			if(!store.MemberUserStore.MemberUserState) {
				let result = await store.MemberUserStore.LoadMemberUserState();

				if(!result) {
					store.Logout();
				}
			}
		}

		loadMemberState();
	}, []);

	if(store.MemberUserStore.MemberUserState) {
		return 	<Routes>
					<Route path="/" element={ <Home /> }></Route>
					<Route path="/statements" element={ <Statements /> }></Route>
					<Route path="/withdrawal" element={ <Withdrawal /> }></Route>
					<Route path="/updatedetails" element={ <UpdateDetails /> }></Route>
					<Route path="/update-address" element={ <UpdateAddress /> }></Route>
					<Route path="/update-email-address" element={ <UpdateEmailAddress /> }></Route>
					<Route path="/update-mobile-number" element={ <UpdatePhoneNumber /> }></Route>
					<Route path="/more" element={ <More /> }></Route>
					<Route path="/faqs" element={ <FAQS /> }></Route>
					<Route path="/contactus" element={ <ContactUs /> }></Route>
					<Route path="/termsandconditions" element={ <TermsAndConditions /> }></Route>
					<Route path="/privacynotice" element={ <PrivacyNotice /> }></Route>
					<Route path="/notifications" element={ <Notifications /> }></Route>
					<Route path="/notification/:id" element={ <Notification /> }></Route>
					<Route path="login" element={<Home />}/>
					<Route path="*" element={<Navigate to="/" />} />
				</Routes>
	} else {
		return 	<Spinner variant="white" />
	}
});

export default Main;