import { useEffect } from "react";
import { Tabs, useSessionStore } from "../../Stores/SessionStore";
import TabPage from "../Core/TabPage";
import { Col, Form, InputGroup, Row, Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/pro-thin-svg-icons";
import { useNavigate } from "react-router-dom";

function EditableDetail({ label, value, onEditClicked }) {
	return 	<Form.Group>
				<Form.Label>{ label }</Form.Label>
				<InputGroup>
					<Form.Control className="bg-white" disabled value={ value }></Form.Control>
					<InputGroup.Text onClick={ onEditClicked } className="text-primary"><FontAwesomeIcon icon={ faPencil } /></InputGroup.Text>
				</InputGroup>
			</Form.Group>
}

export default function UpdateDetails() {
	const navigate = useNavigate();

	const store = useSessionStore();
	const state = store.MemberUserStore.MemberUserState;
	
	useEffect(() => {
		store.setCurrentTab(Tabs.UpdateDetails);
	}, []);

	function getAddress() {
		return 	`${state.MemberDetails.Address.Line1 ?? ""} ` +
				`${state.MemberDetails.Address.Line2 ?? ""} ` +
				`${state.MemberDetails.Address.Line3 ?? ""} ` +
				`${state.MemberDetails.Address.Line4 ?? ""}`;
	}

	return 	<TabPage bgWhite>
				<h5 className="text-primary py-2 pb-3">Member Details - { `${store.Session.User.FirstName} ${store.Session.User.LastName}` }</h5>
				<Row>
					<Col>
						<Form>
							<Stack gap={4}>
								<Form.Group>
									<Form.Label>Service Number</Form.Label>
									<Form.Control className="bg-white" disabled value={ state.MemberDetails.ServiceNumber }></Form.Control>
								</Form.Group>
								<Form.Group>
									<Form.Label>Id Number</Form.Label>
									<Form.Control className="bg-white" disabled value={ state.MemberDetails.IdNumber }></Form.Control>
								</Form.Group>
								<EditableDetail 
									label="Mobile Number" 
									value={ state.MemberDetails.MobileNumber } 
									onEditClicked={ () => navigate("/update-mobile-number") } 
								/>
								<EditableDetail 
									label="Email Address" 
									value={ state.MemberDetails.EmailAddress } 
									onEditClicked={ () => navigate("/update-email-address") } 
								/>
								<EditableDetail 
									label="Postal Address" 
									value={ getAddress() } 
									onEditClicked={ () => navigate("/update-address") } 
								/>
							</Stack>
						</Form>
					</Col>
				</Row>
			</TabPage>
}