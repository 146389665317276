import { Form } from "react-bootstrap";
import { useSessionStore } from "../../Stores/SessionStore";
import { useState } from "react";
import UpdateDetail from "./UpdateDetail";

export default function UpdateEmailAddress() {
	const store = useSessionStore();
	const state = store.MemberUserStore.MemberUserState;

	const [email, setEmail] = useState(state.MemberDetails.EmailAddress);
	const [notValid, setNotValid] = useState(false);

	async function handleUpdate() {
		return store.MemberUsersService.UpdateEmailAddress(email);
	}

	function validate() {
		let valid = email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
		setNotValid(!valid);

		return valid;
	}

	return 	<UpdateDetail detail="Email Address" update={ handleUpdate } validate={ validate }>
				<Form className="mt-4">					
					<Form.Group>
						<Form.Label>Email Address</Form.Label>
						<Form.Control type="email" value={ email } onChange={ e => setEmail(e.target.value) }></Form.Control>
					</Form.Group>
					{
						notValid && <span className="text-danger">Please enter a valid email address.</span>
					}
				</Form>
			</UpdateDetail>
}