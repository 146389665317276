import { Form } from "react-bootstrap";
import { useSessionStore } from "../../Stores/SessionStore";
import { useState } from "react";
import UpdateDetail from "./UpdateDetail";

export default function UpdatePhoneNumber() {
	const store = useSessionStore();
	const state = store.MemberUserStore.MemberUserState;

	const [number, setNumber] = useState(state.MemberDetails.MobileNumber.replace(/ /g, "").replace(/-/g, "").trim());
	const [notValid, setNotValid] = useState(false);

	async function handleUpdate() {
		return store.MemberUsersService.UpdatePhoneNumber(number);
	}

	function validate() {
		let valid = number && number.length === 10 && /^\d+$/.test(number.toLowerCase());
		setNotValid(!valid);

		return valid;
	}

	return 	<UpdateDetail detail="Mobile Number" update={ handleUpdate } validate={ validate }>
				<Form className="mt-4">					
					<Form.Group>
						<Form.Label>Mobile Number</Form.Label>
						<Form.Control maxLength={10} type="tel" value={ number } onChange={ e => setNumber(e.target.value) }></Form.Control>
					</Form.Group>
					{
						notValid && <span className="text-danger">Please enter a valid mobile number.</span>
					}
				</Form>
			</UpdateDetail>
}