import { useEffect } from "react";
import { useSessionStore } from "../../Stores/SessionStore";
import Page from "../Core/Page";
import { Col, Row } from "react-bootstrap";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import Spinner from "../Core/Spinner";

function Notification({ notification, onClick }) {
	return 	<Row className="border-bottom pb-2 mt-3 user-select-none" onClick={ onClick }>
				<Col className={ notification.Read ? null : "fw-bold" }>
					<Row>
						<Col>
							<span className="text-primary">{ notification.Subject }</span>
						</Col>
					</Row>
					<Row>
						<Col>
							<span>{DateTime.fromISO(notification.CreatedOn).toFormat("EEEE, dd LLLL")}</span>
						</Col>
						<Col className="d-flex justify-content-end">
							<span>{DateTime.fromISO(notification.CreatedOn).toFormat("HH:mm")}</span>
						</Col>
					</Row>
				</Col>
			</Row>
}

const Notifications = observer(function Notifications() {
	const store = useSessionStore();
	const navigate = useNavigate();

	useEffect(() => {
		store.NotificationStore.LoadNotifications();
	}, []);

	function handleNotificationClicked(notification) {
		navigate("/notification/" + notification.Id);
	}

	const notifications = store.NotificationStore.Notifications;

	return 	<Page title="Notifications">
				<div>
				{
					notifications &&
					notifications.map((n, i) => <Notification onClick={ () => handleNotificationClicked(n) } notification={ n } key={ i } />)
				}
				{
					!notifications &&
					<Spinner variant="primary" />
				}
				</div>
			</Page>
});

export default Notifications;