import APIBase from "./APIBase";

/**
 * @typedef MemberUserNotificationVM
 * @property {string} Id
 * @property {boolean} Read
 * @property {string} CreatedOn
 * @property {string} Subject
 */

/**
 * @typedef MemberUserNotification
 * @property {string} Id
 * @property {string} Body
 * @property {string} DateCreated
 * @property {string} Subject
 */

/**
 * @typedef NotificationsService
 * @type {NotificationsService}
 */
export default class NotificationsService extends APIBase {
	/**
	 * @param {string} apiUrl 
	 * @param { import("../Stores/SessionStore").SessionStore } sessionStore
	 * */
	constructor(apiUrl, sessionStore) {
		super(apiUrl + "Notifications", sessionStore);
	}

	/**
	 * @returns { import("./APIBase").EnumerableRequestResult<MemberUserNotificationVM> }
	 */
	async GetMemberUserNotifications() {
		return this.Get(`GetMemberUserNotifications`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<MemberUserNotification> }
	 */
	async GetMemberUserNotification(memberUserNotificationId) {
		return this.Get(`GetMemberUserNotification?memberUserNotificationId=${memberUserNotificationId}`);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<boolean> }
	 */
	async ReadMemberUserNotification(memberUserNotificationId) {
		return this.Post(`ReadMemberUserNotification?memberUserNotificationId=${memberUserNotificationId}`, null);
	}

	/**
	 * @returns { import("./APIBase").RequestResult<number> }
	 */
	async GetUnreadNotificationCount() {
		return this.Get(`GetUnreadNotificationCount`);
	}
}