import APIBase from "./APIBase";

/**
 * @typedef User
 * @property {string} Id
 * @property {string} Name
 * @property {string} FirstName
 * @property {string} LastName
 */

/**
 * @typedef MemberUsersService
 * @type {MemberUsersService}
 */
export default class MemberUsersService extends APIBase {
	/**
	 * @param {string} apiUrl 
	 * @param { import("../Stores/SessionStore").SessionStore } sessionStore
	 * */
	constructor(apiUrl, sessionStore) {
		super(apiUrl + "MemberUsers", sessionStore);
	}

	/**
	 * @param {string} idNumber The idNumber of the user
	 * @param {string} password The user's password
	 * @returns { import("./APIBase").RequestResult<import("../Stores/SessionStore").Session> }
	 */
	async Login(idNumber, password) {
		return this.Post(`Login?idNumber=${idNumber}&password=${encodeURIComponent(password)}`);
	}

	/**
	 * @param {string} idNumber The idNumber of the user
	 * @param {string} serviceNumber The user's membe service number
	 * @param {string} phoneNumber The user's phone number
	 * @param {string} password The user's password
	 * @param {string} passwordConfirm The user's password confirmation
	 * @returns { Promise<import("./APIBase").RequestResult<boolean>> }
	 */
	async Register(idNumber, serviceNumber, phoneNumber, password, passwordConfirm) {
		return this.Post(`Register?idNumber=${idNumber}&serviceNumber=${serviceNumber}&phoneNumber=${phoneNumber}&password=${encodeURIComponent(password)}&passwordConfirm=${encodeURIComponent(passwordConfirm)}`);
	}

	async RefreshLogin(memberUserId, refreshToken) {
		return this.Post(`RefreshLogin?memberUserId=${memberUserId}&refreshToken=${encodeURIComponent(refreshToken)}`);
	}

	async RequestPasswordReset(idNumber) {
		return this.Post(`RequestPasswordReset?idNumber=${idNumber}`);
	}

	async ResetPassword(token, password, confirmPassword) {
		return this.Post(`ResetPassword?token=${encodeURIComponent(token)}&password=${encodeURIComponent(password)}&confirmPassword=${encodeURIComponent(confirmPassword)}`);
	}

	async GetMemberState() {
		return this.Get(`GetMemberState`);
	}

	async GetCurrentYearMemberStatement() {
		return this.Get(`GetCurrentYearMemberStatement`);
	}

	async GetPreviousYearMemberStatement() {
		return this.Get(`GetPreviousYearMemberStatement`);
	}

	async CreateWithdrawal(amount, withdrawalDate) {
		return this.Post(`CreateWithdrawal?amount=${encodeURIComponent(amount)}&withdrawalDate=${encodeURIComponent(withdrawalDate)}`);
	}

	async UpdateAddress(address) {
		return this.Post(`UpdateAddress`, address);
	}

	async UpdateEmailAddress(emailAddress) {
		return this.Post(`UpdateEmailAddress?emailAddress=${encodeURIComponent(emailAddress)}`);
	}

	async UpdatePhoneNumber(phoneNumber) {
		return this.Post(`UpdatePhoneNumber?phoneNumber=${encodeURIComponent(phoneNumber)}`);
	}

	async DeleteWithdrawal(transactionId) {
		return this.Post(`DeleteWithdrawal?transactionId=${encodeURIComponent(transactionId)}`);
	}
}