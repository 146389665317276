import { useState, useEffect } from "react";
import { Stack, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

export default function PasswordChecklist({password}) {

    const [atLeastEightLetters, setAtLeastEightLetters] = useState(false);
    const [oneSpecialCharacter, setOneSpecialCharacter] = useState(false);
    const [oneNumber, setOneNumber] = useState(false);
    const [oneUppercaseAndLowercase, setOneUppercaseAndLowercase] = useState(false);

    useEffect(() => {
        
        setAtLeastEightLetters(password.length >= 8);
        setOneSpecialCharacter(password.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/));
        setOneNumber(password.match(/[0-9]/));
        setOneUppercaseAndLowercase(password.match(/[a-z]/) && password.match(/[A-Z]/));
    }, [password]);

    return (
        <Stack>
            <span>
                <FontAwesomeIcon
                    className={atLeastEightLetters ? "text-primary" : " text-secondary"}
                    icon={ faCircleCheck}
                    fixedWidth
                />
                <Form.Text className={atLeastEightLetters ? "text-black" : " text-secondary"}>{"At least 8 letters"}</Form.Text>
            </span>
            <span>
                <FontAwesomeIcon
                        className={oneSpecialCharacter ? "text-primary" : " text-secondary"}
                        icon={ faCircleCheck}
                        fixedWidth
                />
                <Form.Text className={oneSpecialCharacter ? "text-black" : " text-secondary"}>{"One special character"}</Form.Text>
            </span>
            <span>
                <FontAwesomeIcon
                    className={oneNumber ? "text-primary" : " text-secondary"}
                    icon={ faCircleCheck}
                    fixedWidth
                />
                <Form.Text className={oneNumber ? "text-black" : " text-secondary"}>{"One number"}</Form.Text>
            </span>
            <span>
                <FontAwesomeIcon
                    className={oneUppercaseAndLowercase ? "text-primary" : " text-secondary"}
                    icon={ faCircleCheck}
                    fixedWidth
                />
                <Form.Text className={oneUppercaseAndLowercase ? "text-black" : " text-secondary"}>{"One uppercase and lowercase character"}</Form.Text>
            </span>
            
        </Stack>
    )
}
