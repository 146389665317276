import { useState } from "react";
import { Stack, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSessionStore } from "../../../Stores/SessionStore";
import Page from "../../Core/Page";
import Panel from "../../Core/Panel";
import Logo from "../../Core/Logo";
import ErrorModal from "../ErrorModal";
import PasswordField from "../PasswordField";
import IDNumberField from "../IDNumberField";

export default function Login() {
    const store = useSessionStore();
    const navigate = useNavigate();

    const [IdNumber, setIdNumber] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showIDError, setShowIDError] = useState(false);
    const [showPasswordError, setShowPasswordError] = useState(false);
    const [disableLogin, setDisableLogin] = useState(false);
    const [showLoginError, setShowLoginError] = useState(false);
    const [loginErrorMessage, setLoginErrorMessage] = useState("There was an error logging in. Please try again.");
    const [loggingIn, setLoggingIn] = useState(false);

    async function login() {

        setLoggingIn(true);

        let result = await store.Login(IdNumber, password);

        setLoggingIn(false);

        if (result && result.Success) {
            navigate("/");
        } else {
            setLoginErrorMessage(result.Message);
            setShowLoginError(true);
            return false;        
        }
    }
    
    function handleLoginClicked() {
        if (IdNumber && IdNumber > 0 && IdNumber.toString().length === 13 && password.length > 0) {
            setShowIDError(false);
            login();
        } else {
            if (password.length === 0) {
                setShowPasswordError(true);
                setDisableLogin(true);
            } else {
                setShowIDError(true);
                setDisableLogin(true);
            }
        }
    }

    function handleIDNumberChanged(newIDNumber) {
        setIdNumber(newIDNumber);
        if (disableLogin) setDisableLogin(false);
    }

    function handlePasswordChanged(newPassword) {
        setPassword(newPassword);
        setShowPasswordError(false);
        if (disableLogin) setDisableLogin(false);
    }
    
    return (
        <Page bgBlue>
            <Logo />
            <Stack className="justify-content-start mb-2 mt-4">
                <Panel>
                    <Form>
                        <Stack gap={3}>
                            <IDNumberField value={IdNumber} onChange={handleIDNumberChanged} showIDError={showIDError} setShowIDError={setShowIDError}/>
                            <Stack>
                                <PasswordField                                    
                                    label="Password"
                                    value={password}
                                    showPassword={showPassword}
                                    showPasswordError={showPasswordError}
                                    onChange={handlePasswordChanged}
                                    onClick={() => {setShowPassword(!showPassword)}}                                        
                                />
                                <Form.Text className="align-self-end text-primary" onClick={() => {navigate("/request-password")}}>Forgot password</Form.Text>
                            </Stack>
                            <Button className="mt-3" onClick={() => handleLoginClicked()} disabled={disableLogin || loggingIn}>{loggingIn ? "Logging In..." : "Log in"}</Button>
                            <Button onClick={ () => navigate("/") }>Cancel</Button>
                        </Stack>
                    </Form>
                </Panel>
            </Stack>
            <ErrorModal show={showLoginError} onClose={() => setShowLoginError(false)} message={loginErrorMessage}>{loginErrorMessage}</ErrorModal>
        </Page>
    )
}