import { Form, Stack } from "react-bootstrap";
import { useSessionStore } from "../../Stores/SessionStore";
import { useState } from "react";
import UpdateDetail from "./UpdateDetail";

export default function UpdateAddress() {
	const store = useSessionStore();
	const state = store.MemberUserStore.MemberUserState;

	const [line1, setLine1] = useState(state.MemberDetails.Address.Line1);
	const [line2, setLine2] = useState(state.MemberDetails.Address.Line2);
	const [line3, setLine3] = useState(state.MemberDetails.Address.Line3);
	const [line4, setLine4] = useState(state.MemberDetails.Address.Line4);

	function handleUpdateClicked() {		
		return store.MemberUsersService.UpdateAddress({
			Line1: line1,
			Line2: line2,
			Line3: line3,
			Line4: line4
		});
	}

	function ErrorMessage() {
		return <small className="text-danger" style={{ fontSize: "10px" }}>This field cannot be empty</small>
	}

	return 	<UpdateDetail
				detail="Address"
				update={ handleUpdateClicked }
				validate={ () => true }
				disableConsent={ !line1 || !line2 || !line3 }
			>
				<Form>
					<Stack gap={ 4 } className="mt-4">
						<Form.Group>
							<Form.Label>Line 1</Form.Label>
							<Form.Control value={ line1 } onChange={ e => setLine1(e.target.value) }></Form.Control>
							{
								!line1 &&
								<ErrorMessage />
							}
						</Form.Group>
						<Form.Group>
							<Form.Label>Line 2</Form.Label>
							<Form.Control value={ line2 } onChange={ e => setLine2(e.target.value) }></Form.Control>
							{
								!line2 &&
								<ErrorMessage />
							}
						</Form.Group>
						<Form.Group>
							<Form.Label>Line 3</Form.Label>
							<Form.Control value={ line3 } onChange={ e => setLine3(e.target.value) }></Form.Control>
							{
								!line3 &&
								<ErrorMessage />
							}
						</Form.Group>
						<Form.Group>
							<Form.Label>Line 4</Form.Label>
							<Form.Control value={ line4 } onChange={ e => setLine4(e.target.value) } ></Form.Control>
						</Form.Group>
					</Stack>
				</Form>
			</UpdateDetail>
}